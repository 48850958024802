import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import chunk from 'lodash/chunk';

import './index.scss';

import { Section, Container, Row, Column } from 'components/atoms';
import TextIconsItem from 'components/molecules/TextIconsItem';

const TextIconsIndustry = ({ data, className, spacer, wide, services }) => {
  const {
    columns,
    background_fill,
    show_title,
    width,
    text_icons,
    numbered_icons,
  } = data || [];

  const isLarge = width === 'large' || columns === 1;

  const items = (text_icons || []).map((item, index) => (
    <TextIconsItem
      data={{
        show_title,
        item,
        numbered_icons,
        index,
        isLarge,
        background_fill,
      }}
      key={index}
    />
  ));

  const dataColumns = numbered_icons
    ? chunk(items, text_icons.length / columns)
    : items;

  const dataArray = dataColumns.map((item, index) => {
    let lg = isLarge ? `${12 / columns - 1}` : `${12 / columns}`;
    let offset = index % 2 !== 0;

    return (
      <Column
        col={numbered_icons ? '9' : isLarge ? '12' : '6'}
        lg={lg}
        key={index}
        className={cn(
          'text-icons-industry__column',
          {
            'text-icons-industry__column_large': isLarge,
          },
          { 'text-icons-industry__column_numbered': numbered_icons },
          {
            'offset-lg-2':
              offset && isLarge && +columns !== 1 && !numbered_icons,
          },
          {
            'text-icons-industry__column_numbered--desktop-hide':
              +columns !== 1 && numbered_icons,
          }
        )}
      >
        {item}
      </Column>
    );
  });

  const NumberedIconsGridDesktop = () => {
    let col = [];

    let itemsLength = dataColumns[0].length;

    for (let j = 0; j < itemsLength; j++) {
      for (let i = 0; i < columns; i++) {
        if (dataColumns[i][j]) {
          col.push(dataColumns[i][j]);
        }
      }
    }

    return (
      <Column
        lg={+columns === 2 ? '12' : '10'}
        xl={+columns === 2 ? '12' : '9'}
        className={cn(
          'text-icons-industry__grid',
          +columns === 2 && 'text-icons-industry__grid--double'
        )}
        style={{
          gridTemplateColumns: `repeat(${+columns}, 1fr)`,
        }}
      >
        {col}
      </Column>
    );
  };

  const lg = isLarge ? '9' : wide ? '7' : '6';
  const columnOffset = services ? 'offset-lg-1' : 'offset-lg-2';

  return (
    <Section
      className={cn(
        'text-icons-industry',
        spacer && 'text-icons-industry_spacer',
        [className]
      )}
    >
      <Container>
        <Row>
          <Column
            col="12"
            lg="9"
            className={cn(`col-lgplus-${lg}`, columnOffset)}
          >
            <div
              className={cn('text-icons-industry__content', {
                'text-icons-industry__content_background':
                  !numbered_icons && background_fill,
                'text-icons-industry__content--wide': !background_fill,
                'text-icons-industry__content--services': services,
              })}
            >
              <Container type={isLarge ? 'fluid' : 'default'}>
                <Row
                  className={cn({
                    'text-icons-industry__row_large': isLarge,
                  })}
                >
                  {+columns !== 1 && numbered_icons && (
                    <NumberedIconsGridDesktop />
                  )}
                  {dataArray}
                </Row>
              </Container>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

TextIconsIndustry.defaultProps = {
  className: '',
  wide: false,
};

TextIconsIndustry.propTypes = {
  data: PropTypes.object.isRequired,
  spacer: PropTypes.bool,
  className: PropTypes.string,
  wide: PropTypes.bool,
};

export default TextIconsIndustry;
