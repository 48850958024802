import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Text, Image } from 'components/atoms';

const TextIconsItem = ({ data, className }) => {
  const {
    show_title,
    item,
    numbered_icons,
    index,
    isLarge,
    background_fill,
  } = data;
  const { text, title, icon } = item;
  const { url } = icon || {};
  const { source_url, alt_text, title: image_title, media_details } = url || {};
  const { width, height } = media_details || {};

  return (
    <div
      className={cn(
        'text-icons-industry__item',
        { 'text-icons-industry__item_numbered': numbered_icons },
        { 'text-icons-industry__item_large': isLarge },
        { 'text-icons-industry__item_background': background_fill },
        [className]
      )}
    >
      {numbered_icons ? (
        <Text className="text-icons-industry__item__number">
          {index < 9 ? `0${index + 1}` : index + 1}
        </Text>
      ) : (
        source_url && (
          <div className="text-icons-industry__item__image-container">
            <Image
              className="text-icons-industry__item__image"
              src={source_url}
              alt={alt_text}
              title={image_title}
              width={width}
              height={height}
            />
          </div>
        )
      )}

      <div className="text-icons-industry__item__description">
        {show_title && <Text className="body-sm body-sm_bold">{title}</Text>}
        <Text tag="p">{text}</Text>
      </div>
    </div>
  );
};

TextIconsItem.defaultProps = {
  className: '',
};

TextIconsItem.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default TextIconsItem;
